import React from "react";

import { Link } from "react-router-dom";

/**
 * NOTES:
 * - On styling:
 * For this test I moved the styles to styles.css.
 * - On conditional rendering:
 * The conditional statement that checks whether or not to display an image
 * exists within the 'CardImage' function. Used in the component as:
 * <CardImage />
 * - On default props:
 * I have no idea whether this approach of handling defaults is the right/best one.
 * Next step would be to try variations on setting defaults.
 */

/*
1. Card Class
2. Defaults 
*/

// 1. Card Class /////////////////////////////////////////////


function CardImage(props) {
  const isImageURL = props.image;

  // If an image was passed:

    return (
      <div className="styleImage">
        <img
          style={{ width: props.width + "px", marginTop: "-20%" }}
          src={props.image}
          alt="Seattle"
        />
      </div>
    );
 
  
}

function CardContent(props) {
  return (
    <div className="styleCardContent">
      <p className="styleCardTitle" style={{fontSize:'1rem'}}>{props.title}</p>
      <p className="styleLocationLabel">{props.location}</p>
      <p className="styleDescription">{props.description}</p>
    </div>
  );
}

export default class Card extends React.Component {
  
  render() {
    const mystyle = {
      border: this.props.color,
      color: this.props.textColor

    };
    return (
      <div style={{ width: this.props.width + "px", }} data-aos="fade-up" data-aos-duration="1300">
        <div className="styleCard" style={{maxHeight:"267px"}}>
          <CardImage image={this.props.image} width={this.props.width} />
          <CardContent
            title={this.props.title}
            location={this.props.location}
            description={this.props.description}
          />
          
       
       
          <a className="custom-btn btn-15 aos-init aos-animate" style={mystyle} >
              {this.props.studio}
            </a>
          
        </div>
      </div>
    );
  }
}

// 2. Defaults /////////////////////////////////////////////
Card.defaultProps = {
  width: 350,
  title: "Template - Card Title",
  location: "Location label",
  description: "Template description textbox",
  studio: "studio"
};
