import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";

import Home from "./Home.js";
import Studios from "./Studios.js";
import StudioSpec from "./Studiospec.js";


const Router = () => {
   return(
       <BrowserRouter>
            <Routes>
                <Route element = { <Home/> }  path="/" exact />
                <Route element = { <Studios/>} path="/studios/" exact />
                <Route element = { <StudioSpec/>} path="/studios/specific/:studio" exact/> 
            </Routes>
       </BrowserRouter>
   )
}

export default Router;