import React from 'react'

import "./style/studiodisp.css";
import Carousel from './Carousel';


const Studiodisp = () => {
    return (
        <div className='StdMor'>
            <div className='stdsobretexto' data-aos="fade-right" data-aos-duration="1500" data-aos-delay="300">
                <div>
                 <h3 className='sobreh'>STUDIOS DISPONÍVEIS</h3>
                 </div>
                <p>
                Com designs arrojados,
                acabamentos de alta qualidade e um layout inteligente
                que maximiza o uso do espaço. Os estúdios do <strong>ELOS PRUDENTE </strong>
                representam uma excelente oportunidade de investimento, com potencial
                de valorização em uma região nobre de Curitiba. 
                </p>
                <a className="custom-btn btn-15 aos-init aos-animate" href='https://elosprudente.com.br/studios'>
                ver studios
            
                </a>
            </div>
            <div  className="carro"data-aos="fade-up" data-aos-duration="1300" data-aos-delay='300'>
                <Carousel>
                <div>
                        <img
                        src="./1/1.jpg"
                        alt="img1"
                        className='imgc'
                        />
                        <p className='unity'>de 19,25m² até 19,44m²</p>
                        </div>
                    <div>
                    <img
                        src="./2/2.jpg"
                        alt="img1"
                        className='imgc'
                        />
                        <p className='unity'>de 20,83m² até 21,4m²</p>
                    </div>
                    <div>
                    <img
                        src="./4/3.jpg"
                        alt="img1"
                        className='imgc'
                        />
                        <p className='unity'>29,32m²</p>
                    </div>
                    <div>
                    <img
                        src="./5/1.jpg"
                        alt="img1"
                        className='imgc'
                        />
                        <p className='unity'>30m²</p>
                    </div>
                    <div>
                    <img
                        src="./duplex-01/duplex-01.jpg"
                        alt="img1"
                        className='imgc'
                        />
                        <p className='unity'>51,95m²</p>
                    </div>
                    <div>
                    <img
                        src="./duplex-02/duplex-02.jpg"
                        alt="img1"
                        className='imgc'
                        />
                        <p className='unity'>56,74m²</p>
                    </div>
                    <div>
                    <img
                        src="./duplex-03/duplex-03.jpg"
                        alt="img1"
                        className='imgc'
                        />
                        <p className='unity'>40,05m²</p>
                    </div>
                    <div>
                    <img
                        src="./tipo/tipo.jpg"
                        alt="img1"
                        className='imgc'
                        />
                        <p className='unity'>25,06m²</p>
                    </div>
                    </Carousel>
            </div>
        </div>
    )
}

export default Studiodisp;