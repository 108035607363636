import React from 'react';

import "./style/Sobre.css";

const Sobre = () => {
    return(
        <div className='SobreMor'>
            <div className='sobretexto' data-aos="fade-right" data-aos-duration="1500" data-aos-delay="300">
                <div>
                 <h1 className='sobreh'>ALAVANQUE SEU PATRIMÔNIO
 <br/> COM COMPACTOS DE ALTO NÍVEL.</h1>
                 </div>
                <p>
                Apresentamos o mais novo empreendimento da Concrezatto: 
Elos Prudente. Inspirado na força das conexões que transformam e constroem o futuro, Elos é um símbolo da união entre sofisticação, inovação e valorização do seu patrimônio. Cada detalhe deste projeto foi cuidadosamente pensado para criar um ambiente onde as ligações entre design, funcionalidade e localização estratégica formam uma corrente sólida de oportunidades para nossos investidores.
                </p>
                <a href="#contato"className="custom-btn btn-15 aos-init aos-animate" >
                Saiba Mais
            
                </a>
            </div>
            <div className='imgmor'>
                <img src="./sobre.png" className='sobreimg' data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300"></img>
            </div>
        </div>
    )
}

export default Sobre;