import React,  {useState, useEffect} from 'react';

import { useParams } from 'react-router';





import "./style/informacoes.css";



const StudioSpec = () => {


    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(data);
    const [loading, setLoading] = useState(false);
    let componentMounted = true; 
    const studio = useParams();

    let metragemUm;
    let metragemDois = "";
    let metragemtres = "";
    let metragemQua = "";

    let titulo;
    let texto = [];


    useEffect(() => {
        console.log(studio.studio)

        const getStudios = async () => {
 
        }
        getStudios();

    }, []);
             

    
  
      
    const ShowStudios = () => {
        let timer = 0;
        if(studio.studio == 1) { titulo ="";texto[0]="201, 301, 401, 501, 601";texto[4] = "Metragem privativa: 19,25m²"; }
        if(studio.studio == 2) {titulo = "";texto[0] = " 102, 103, 106, 107, 108, 202, 203, 207, 208, 302, 303, 307, 402, 403, 502, 503 "; texto[4]="metragem privativa: 20,83m² até 21,4m²"; texto[5]="*unidades 102, 103, 106, 107 e 108 possuem garden"}
        if(studio.studio == 4) {titulo = "";texto[0] = "104, 204"; texto[4] = "Metragem privativa:29,32m²";texto[5]="*unidade 104 possui garden"  }
        if(studio.studio == 5) {titulo = "";texto[0] = "105 - GARDEN"; texto[4]="Metragem privativa:30m²"}
        if(studio.studio == 6) {titulo = "";texto[0] = "605 - DUPLEX"; texto[4]="Metragem privativa:56,74m²"}
        return (
            <div className="studiostudio" >
                
                <div className="imagens_studio">

                <img src={"/" + studio.studio+"/1.jpg"} data-aos="fade-up" data-aos-duration="1300" data-aos-delay={"" + timer}/>
                <img src={"/" + studio.studio+"/2.jpg"} data-aos="fade-up" data-aos-duration="1300" data-aos-delay={"" + timer}/>
                <img src={"/" + studio.studio+"/3.jpg"} data-aos="fade-up" data-aos-duration="1300" data-aos-delay={"" + timer}/>
                <img src={"/" + studio.studio+"/4.jpg"} data-aos="fade-up" data-aos-duration="1300" data-aos-delay={"" + timer}/>
                <img src={"/" + studio.studio+"/5.jpg"} data-aos="fade-up" data-aos-duration="1300" data-aos-delay={"" + timer}/>
                <img src={"/" + studio.studio+"/6.jpg" } data-aos="fade-up" data-aos-duration="1300" data-aos-delay={"" + timer}/>
                    
                
                </div>
                <div className="informacoes_dad">
                <div className="informacoes" style={{alignContent:'space-around'}}>
                    <h1 style={{textAlign: "center"}}>UNIDADES DISPONÍVEIS:</h1>
                    <h1>{titulo}</h1>

                    <p> 
                       {texto[0]}
                 
                    </p>
                    <div className="admor">
                                            <p id='adendos'><b class="ad1">{texto[4]}</b></p>
                    <br className='breq'/>
                    <p   id='adendos'><b class="ad2">{texto[5]}</b></p>
                    </div>

                    <a style={{margin: '0 auto 0px auto'}}className="custom-btn btn-15 aos-init aos-animate" href={"https://api.whatsapp.com/send?phone=5541996480900"}>
                        saber mais
                    </a>
                    <a style={{margin: '0.2em auto 0px auto'}}className="custom-btn btn-15 aos-init aos-animate" href={"https://elosprudente.com.br/studios"}>
                         voltar
                    </a>

                   
                </div>
                </div>
            </div>
        )
    }

    const Loading = () => {
        return (
            <>
                Carregando...
            </>
        )
    }
  

    return (
        <div style={{}}>
            {loading ? <Loading/> : <ShowStudios/>}
        </div>
    )
}

export default StudioSpec;